import React from "react"

import DefaultLayout from "../layout/default"

import pageStyles from "../styles/_V2/pagesStyles/styles.module.scss"

import PartnerProgramProductPresentation from "../pagesSections/partner-program/ProductPresentation"

import AdditionalServices from "../pagesSections/index/AdditionalServices"
import CtaForm, { CTA_FROM_THEMES } from "../pagesSections/index/CtaForm"
/* import BusinessTypes from '../pagesSections/index/BusinessTypes' */
import KnowledgeBase from "../pagesSections/index/KnowledgeBase"

export default function PartnerProgramPage() {
	const metaTags = [
		{
			name: "description",
			content:
				"CRM-система для ресторанов и кафе от Quick Resto  → Программы лояльности гостей → Акции, бонусные программы, персональные скидки → Всё инструменты для того, чтобы гость вернулся",
		},
		{
			property: "og:url",
			content: "https://quickresto.ru/warehouse/",
		},
		{
			property: "og:title",
			content:
				"CRM-система и программы лояльности для ресторанов и кафе | Quick Resto",
		},
		{
			property: "og:description",
			content:
				"CRM-система для ресторанов и кафе от Quick Resto  → Программы лояльности гостей → Акции, бонусные программы, персональные скидки → Всё инструменты для того, чтобы гость вернулся",
		},
		{
			property: "og:type",
			content: "website",
		},
		{
			property: "og:image",
			content:
				"https://quickresto.ru/static/d8b4bc33cc20e36d4a58055bacc90382/813ea/warehouse-presentation.webp",
		},
	]
	return (
		<DefaultLayout
			title="CRM-система и программы лояльности для ресторанов и кафе | Quick Resto"
			metaTags={metaTags}
			linkCanonical="https://quickresto.ru/warehouse/"
		>
			<main className={pageStyles.pageWrapper}>
				<PartnerProgramProductPresentation />

				<CtaForm isFry={false} theme={CTA_FROM_THEMES.CYAN} />

				<CtaForm isFry={false} theme={CTA_FROM_THEMES.FUCHSIA} />

				<AdditionalServices />

				{/* <BusinessTypes type="compact" /> */}

				<KnowledgeBase type="horizontal" />
			</main>
		</DefaultLayout>
	)
}
