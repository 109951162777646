import React from "react"
import ProductPresentationBase from "../../../components/_V2/ProductPresentationBase"
import presentationData from "./data"
import Backdrop from "./assets/Backdrop"
import styles from "./styles.module.scss"

export default function PartnerProgramProductPresentation() {
	return (
		<ProductPresentationBase
			sectionClass={styles.section}
			containerClass={styles.content}
			data={presentationData}
			backdropClass={styles.backdrop}
			backdropMobileClass={styles.backdropMobile}
		/>
	)
}
