import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { pagesLinks } from "../../../pages-data/_V2/common/links"
import styles from "./styles.module.scss"

export default {
	pageName: "новые возможности для бизнеса",
	desktopTitle: "Партнёрская программа Quick Resto",
	subTitle: "",
	cta: pagesLinks.registration.text,
	desktopCtaDetails:
		"За 10 минут, на любом оборудовании, с удобным интерфейсом. И две недели бесплатно.",
	ctaLink: pagesLinks.registration,
	figure: (
		<StaticImage
			src="./assets/group-program-presentation.png"
			alt="новые возможности для бизнеса"
			className={styles.image}
			objectFit="contain"
			placeholder="blurred"
			quality={90}
		/>
	),
}
